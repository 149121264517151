<template>
  <div>
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="PaperclipIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Link Externo</span>
        </template>
        <Termo v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="PercentIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Taxas de Juros</span>
        </template>
        <TaxasDeJuros v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Critérios de Elegibilidade</span>
        </template>
        <CriteriosElegibilidade v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="FolderPlusIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Modelos de Documentos</span>
        </template>
        <ModelosDeDocumento v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Parâmetros</span>
        </template>
        <Parametros v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Recursos</span>
        </template>
        <Recursos v-if="options" :general-data="options" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="ShieldOffIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Restrições</span>
        </template>
        <Restricoes v-if="options" :general-data="options" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import TaxasDeJuros from '@/views/pages/Configuracoes/Produto/TaxasParametrizadas/TaxasParametrizadas.vue'
  import CriteriosElegibilidade from './CriteriosElegibilidade/CriteriosElegibilidade.vue'
  import ModelosDeDocumento from './ModeloDeDocumentos/ModeloDocumento.vue'
  import Parametros from './Parametros/Parametros.vue'
  import Recursos from './Recursos/Recursos.vue'
  import Restricoes from './Restricoes/Restricoes.vue'
  import Termo from './Termo/Termo.vue'

  export default {
    name: 'ProdutoTabs',
    components: {
      CriteriosElegibilidade,
      TaxasDeJuros,
      ModelosDeDocumento,
      Parametros,
      Recursos,
      Restricoes,
      Termo,
    },
    props: {
      parametro: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        options: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        tipoIndice: '',
      }
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        useJwt
          .get(`produto/${this.parametro.id}`)
          .then((response) => {
            this.options = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
