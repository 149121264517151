<template>
  <div>
    <b-card-code no-body v-if="acesso.ProdutoConsulta">
      <!-- Filtros -->
      <b-card-body>
        <b-row>
          <b-col md="4" v-if="this.pesquisar.tipoOpcoes != 2">
            <b-form-group label="Nome do Produto" label-for="descricao">
              <b-form-input
                id="nomeDoProduto"
                placeholder="Nome do Produto"
                v-model="pesquisar.nome"
                v-on:keydown.enter="carregarGrid"
              />
            </b-form-group>
          </b-col>

          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>

          <b-col md="auto" aling="right" v-show="this.acesso.ProdutoCriar">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="cadastrar()"
            >
              Novo Cadastro
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Tabela -->
      <b-table
        v-show="items.length > 0"
        striped
        responsive
        class="position-relative"
        :per-page="pesquisar.perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isBusy"
        :current-page="pesquisar.currentPageTomador"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong> Carregando...</strong>
          </div>
        </template>

        <template #cell(opcoes)="row">
          <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                title="Este botão contém as opções disponíveis para este registro"
              >
                <feather-icon icon="SettingsIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="configuracoes(row.item)" v-if="acesso.ProdutoConfigurar">
              <feather-icon icon="ToolIcon" size="16" />
              <span class="align-middle ml-50">Configurações</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editar(row.item)" v-if="acesso.ProdutoEditar">
              <feather-icon icon="EditIcon" size="16" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="excluir(row.item.id)" v-if="acesso.ProdutoExcluir">
              <feather-icon icon="XIcon" size="16" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(avatar)="row">
          <b-avatar :src="row.item.foto != null ? row.item.foto.foto : null" />
        </template>
        <template #cell(categorias)="row">
          <span class="margimBadge" v-for="segurado in row.item.segurado" :key="segurado.id">
            <b-badge pill variant="primary" class="mr-1">
              {{ filterInativo(String(segurado.situacaoPrevidenciaria)).toUpperCase() }}
            </b-badge>
          </span>
        </template>
      </b-table>

      <!-- Paginação -->
      <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          style="align-items: center"
          label="Por Página"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
        </b-form-group>

        <!-- pagination -->
        <b-pagination
          v-model="pesquisar.currentPageTomador"
          :total-rows="totalRows"
          :per-page="pesquisar.perPage"
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>

      <!-- Modal de cadastro -->
      <b-modal
        id="modal-cadastro-produto"
        ref="modal-cadastro-produto"
        :title="tituloModal"
        size="md"
        hide-footer
        no-close-on-backdrop
      >
        <ModalCadatroProduto :parametro="parametroModal" @closeModal="fecharModalCadastro" />
      </b-modal>

      <!-- Modal de Configurações -->
      <b-modal
        id="modal-configuracoes-produto"
        ref="modal-configuracoes-produto"
        :title="tituloModalConfiguracoes"
        size="xl"
        hide-footer
        no-close-on-backdrop
      >
        <ProdutoTabs :parametro="parametroModalConfiguracoes" @closeModal="fecharModalConfiguracoes" />
      </b-modal>
    </b-card-code>

    <b-card class="text-center" v-else>
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para realizar pesquisas.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import { PascalCaseComEspaco, formatarData } from '@/libs/utils'
  import { formatarValor } from '@/utils/Functions/Formatacao/Formatacao'
  import { isLetter, isNumber } from '@/libs/utils.js'
  import ModalCadatroProduto from './components/ModalCadatroProduto.vue'
  import ProdutoTabs from './ProdutoTabs.vue'

  export default {
    components: {
      ModalCadatroProduto,
      ValidationProvider,
      ValidationObserver,
      formatarData,
      BCardCode,
      Cleave,
      ProdutoTabs,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
        isLetter,
        isNumber,
        formatarValor,

        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPageTomador: 1,
        },
        loading: false,

        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          clevePhone: { phone: true, phoneRegionCode: 'BR', delimiter: ' ' },
          cleaveCEP: {
            delimiters: ['.', '.', '-'],
            blocks: [2, 3, 3],
          },
        },
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'nome', label: 'Nome' },
          { key: 'montanteInicial', label: 'Montante Inicial', formatter: (value) => formatarValor(value) },
          { key: 'saldo', label: 'Saldo', formatter: (value) => formatarValor(value) },
          { key: 'createdAt', label: 'Data de Cadastro', formatter: (value) => formatarData(value) },
        ],
        items: [],
        acesso: {
          ProdutoConsulta: false,
          ProdutoConfigurar: false,
          ProdutoEditar: false,
          ProdutoCriar: false,
          ProdutoExcluir: false,
        },
        dados: {},
        tomadorWithOrg: {},
        log: [],
        categoria: [
          {
            Ativo: 'primary',
            Inativo: 'light-success',
            Pensionista: 'light-danger',
          },
        ],
        chartMargemEfetivos: '0',
        chartMargemEstavel: '0',
        chartMargemInativo: '0',
        chartMargemPensionista: '0',
        isLoadingIndicadores: false,
        isEditing: true,
        tituloModal: 'Cadastro de Produto',
        parametroModal: {
          id: null,
          nome: null,
          valor: null,
        },
        tituloModalConfiguracoes: 'Configurações do Produto',
        parametroModalConfiguracoes: {
          id: null,
          nome: null,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ProdutoConsulta = true
        this.acesso.ProdutoConfigurar = true
        this.acesso.ProdutoEditar = true
        this.acesso.ProdutoCriar = true
        this.acesso.ProdutoExcluir = true
      } else {
        this.acesso.ProdutoConsulta = this.userData.GrupoAcesso.ProdutoConsulta
        this.acesso.ProdutoConfigurar = this.userData.GrupoAcesso.ProdutoConfigurar
        this.acesso.ProdutoEditar = this.userData.GrupoAcesso.ProdutoEditar
        this.acesso.ProdutoCriar = this.userData.GrupoAcesso.ProdutoCriar
        this.acesso.ProdutoExcluir = this.userData.GrupoAcesso.ProdutoExcluir
      }

      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        useJwt
          .post('produto/getAllProducts', {
            nome: this.pesquisar.nome,
          })
          .then((response) => {
            this.isBusy = false
            this.items = response.data
            this.totalRows = this.items.length
            if (this.items.length == 0) {
              this.$message.error('Não foram encontrados tomadores!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao carregar os produtos cadastrados!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      cadastrar() {
        this.parametroModal = {}
        this.tituloModal = 'Cadastro de Produto'
        this.$refs['modal-cadastro-produto'].show()
      },
      excluir(productId) {
        this.isBusy = true

        useJwt
          .delete('produto', productId)
          .then((response) => {
            this.$message.success('Produto excluído com sucesso!')
            this.carregarGrid()
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao excluir o produto!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      editar(item) {
        this.tituloModal = 'Editar Produto'
        this.parametroModal = {
          id: item.id,
          nome: item.nome,
          valor: item.montanteInicial,
        }
        this.$refs['modal-cadastro-produto'].show()
      },
      fecharModalCadastro() {
        this.$refs['modal-cadastro-produto'].hide()
        this.carregarGrid()
      },
      fecharModalConfiguracoes() {
        this.$refs['modal-configuracoes-produto'].hide()
      },
      configuracoes(item) {
        this.tituloModalConfiguracoes = `Configurações do Produto - ${item.nome}`
        this.parametroModalConfiguracoes = {
          id: item.id,
          nome: item.nome,
        }
        this.$refs['modal-configuracoes-produto'].show()
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
