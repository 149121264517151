<template>
  <div>
    <b-tabs pills>
      <b-tab title="Arrecadação">
        <b-card-text>
          <ParametrosArrecadacao :generalData="generalData" />
        </b-card-text>
      </b-tab>

      <b-tab title="Renegociação">
        <b-card-text>
          <ParametrosRenegociacao :generalData="generalData" />
        </b-card-text>
      </b-tab>

      <b-tab title="Boleto Bancário">
        <b-card-text>
          <ParametrosBoleto :generalData="generalData" />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import ParametrosBoleto from './Boleto/Boleto.vue'
  import ParametrosArrecadacao from './Arrecadacao/Arrecadacao.vue'
  import ParametrosRenegociacao from './Renegociacao/Renegociacao.vue'

  export default {
    components: {
      ParametrosArrecadacao,
      ParametrosRenegociacao,
      ParametrosBoleto,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
  }
</script>
././Boleto.vueArrec./Renegociacao.vueadacao.vue
