<template>
  <b-card>
    <validation-observer ref="formRestricoes">
      <b-form>
        <b-row>
          <!-- Juros Após Vencimento -->
          <b-col sm="3">
            <b-form-group label="Juros Após Vencimento" label-for="limiteParcelas">
              <validation-provider #default="{ errors }" name="Juros Após Vencimento" rules="required">
                <b-input-group :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-decimal
                    id="renegociacaoJurosAposVencimento"
                    v-model="optionsLocal.renegociacaoJurosAposVencimento"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="Juros Após Vencimento"
                    placeholder="Juros (%)"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Tipo de Juros  -->
          <b-col md="3">
            <b-form-group label="Tipo de Juros" label-for="tipoJuros">
              <validation-provider #default="{ errors }" name="Tipo de Juros" rules="required">
                <b-form-select
                  name="Tipo de Juros"
                  id="renegociacaoTipoJuros"
                  v-model.number="optionsLocal.renegociacaoTipoJuros"
                  :options="tipoJuros"
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Multa -->
          <b-col sm="3">
            <b-form-group label="Multa" label-for="multa">
              <validation-provider #default="{ errors }" name="Multa" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input
                    type="text"
                    v-decimal
                    id="multa"
                    v-model="optionsLocal.renegociacaoMulta"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    placeholder="Multa (%)"
                  />
                  <b-input-group-append>
                    <span class="input-group-text">%</span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Multa Após  -->
          <b-col md="3">
            <b-form-group label="Multa Após" label-for="tipoMulta">
              <validation-provider #default="{ errors }" name="Multa Após" rules="required">
                <b-form-select name="Multa Após" v-model.number="optionsLocal.renegociacaoMultaApos" :options="tipoMultaApos">
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Incidência de Juros  -->
          <b-col md="3">
            <b-form-group label="Incidência de Juros" label-for="tipoIncidenciaJuros">
              <validation-provider #default="{ errors }" name="Incidência de Juros" rules="required">
                <b-form-select
                  name="Incidência de Juros"
                  v-model="optionsLocal.renegociacaoTipoIncidenciaJuros"
                  :options="opcoesTipoIncidenciaJuros"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          style="width: 160px"
          variant="primary"
          class="mt-2 mr-1"
          @click.prevent="authESalve"
        >
          Salvar
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          style="width: 160px"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="limpar"
        >
          Limpar
        </b-button>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import numberDecimalMask from '@/@core/directives/numberDecimalMask'
  import { formatarParaNumber } from '@/libs/utils'

  export default {
    components: {
      BCardActions,
      AutenticaSenha,
      ValidationObserver,
      ValidationProvider,
      ToastificationContent,
      required,
    },
    directives: {
      Ripple,
      decimal: numberDecimalMask,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        number: 0.0,
        tipoMultaApos: [
          { value: '', text: 'Selecione' },
          { value: '1', text: '1 Meses' },
          { value: '2', text: '2 Meses' },
          { value: '3', text: '3 Meses' },
          { value: '4', text: '4 Meses' },
          { value: '5', text: '5 Meses' },
          { value: '6', text: '6 Meses' },
          { value: '7', text: '7 Meses' },
          { value: '8', text: '8 Meses' },
          { value: '9', text: '9 Meses' },
          { value: '10', text: '10 Meses' },
          { value: '11', text: '11 Meses' },
          { value: '12', text: '12 Meses' },
        ],
        tipoIndiceAtualizacao: [
          { value: '', text: 'Selecione' },
          { value: 'IPCA', text: 'IPCA' },
          { value: 'INPC', text: 'INPC' },
        ],
        opcoesTipoIncidenciaJuros: [
          { value: '', text: 'Selecione' },
          { value: 'sobreCadaParcela', text: 'Juros e multa sobre cada parcela' },
          // { value: 'sobreValorDevido', text: 'Juros e multa sobre o valor devido' },
        ],
        tipoJuros: [
          { value: '', text: 'Selecione' },
          { value: 'aoMesCalculoDiario', text: 'Ao Mês - Cálculo diário' },
          { value: 'aoMesNaoCumulativo', text: 'Ao Mês - Não Cumulativo' },
        ],
        items: [],
        acesso: {},
      }
    },
    mounted() {
      this.optionsLocal = {
        ...this.optionsLocal,
        renegociacaoJurosAposVencimento: this.optionsLocal.renegociacaoJurosAposVencimento
          ? String(this.optionsLocal.renegociacaoJurosAposVencimento).replace('.', ',')
          : '0,00',
        renegociacaoMulta: this.optionsLocal.renegociacaoMulta
          ? String(this.optionsLocal.renegociacaoMulta).replace('.', ',')
          : '0,00',
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          renegociacaoTipoIncidenciaJuros: '',
          renegociacaoMulta: '',
          renegociacaoTipoJuros: '',
          renegociacaoJurosAposVencimento: '',
        }
      },
      authESalve(bvModalEvt) {
        bvModalEvt.preventDefault()
        this.$refs.formRestricoes.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        const parametros = {
          ...this.optionsLocal,
          renegociacaoJurosAposVencimento: formatarParaNumber(this.optionsLocal.renegociacaoJurosAposVencimento),
          renegociacaoMulta: formatarParaNumber(this.optionsLocal.renegociacaoMulta),
        }

        useJwt
          .put('parametro/renegociacao/' + this.generalData.id, parametros)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao salvar registro')
          })
      },
    },
  }
</script>

<style lang="scss"></style>
