var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formRestricoes"},[_c('b-form',[_c('b-card-text',{staticClass:"mb-1"},[_c('small',{staticClass:"textoObservacao"},[_vm._v(" Para mais informações do parâmetro, passe o mouse por cima dele. ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Limite de Parcelas (quantidade)","label-for":"limiteParcelas"}},[_c('validation-provider',{attrs:{"name":"limiteParcelas","rules":"required|max_value:120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"limiteParcelas","state":errors.length > 0 ? false : null,"placeholder":"Limite de Parcelas","type":"number"},model:{value:(_vm.limiteParcelas),callback:function ($$v) {_vm.limiteParcelas=_vm._n($$v)},expression:"limiteParcelas"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Valor Máximo de Empréstimo","label-for":"valorMaximoEmprestimo"}},[_c('validation-provider',{attrs:{"name":"Valor Máximo de Empréstimo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('span',{staticClass:"input-group-text"},[_vm._v("R$")])]),_c('b-form-input',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"form-control-merge",attrs:{"type":"text","id":"valorMaximoEmprestimo","state":errors.length > 0 ? false : null,"name":"Valor Máximo de Empréstimo","placeholder":"Valor Máximo de Empréstimo"},model:{value:(_vm.valorMaximoEmprestimo),callback:function ($$v) {_vm.valorMaximoEmprestimo=$$v},expression:"valorMaximoEmprestimo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Dia do Repasse","label-for":"diaRepasse"}},[_c('validation-provider',{attrs:{"name":"Dia do Repasse","rules":"required|min_value:1|max_value:31"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"diaRepasse","state":errors.length > 0 ? false : null,"name":"Dia do Repasse","placeholder":"Dia do Repasse"},model:{value:(_vm.diaRepasse),callback:function ($$v) {_vm.diaRepasse=$$v},expression:"diaRepasse"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Dia do Fechamento da Folha","label-for":"diaVencimento"}},[_c('validation-provider',{attrs:{"name":"Dia do Fechamento da Folha","rules":"required|min_value:1|max_value:31"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"diaVencimento","state":errors.length > 0 ? false : null,"name":"Dia do Fechamento da Folha","placeholder":"Dia do Fechamento da Folha"},model:{value:(_vm.diaVencimento),callback:function ($$v) {_vm.diaVencimento=$$v},expression:"diaVencimento"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }