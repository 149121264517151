<template>
  <div>
    <validation-observer ref="formularioCadastroProduto">
      <b-card-code title="Produto" icon="UserIcon">
        <validation-provider rules="required" name="Nome" v-slot="{ errors }">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input
              id="nome"
              v-model="dados.nome"
              :state="errors.length > 0 ? false : null"
              placeholder="Nome"
            ></b-form-input>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider rules="required" name="Montante Inicial" v-slot="{ errors }">
          <b-form-group label="Valor do Montante Inicial" label-for="valor">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">R$</span>
              </b-input-group-prepend>
              <b-form-input
                :disabled="dados.id"
                id="valor"
                v-model="dados.valor"
                v-number="number"
                placeholder="Valor do Montante Inicial"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-button block class="mt-2" type="submit" variant="primary" @click="confirmar"> Salvar </b-button>
      </b-card-code>
    </validation-observer>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { VueSelect } from 'vue-select'
  import { capitalizeText, formatarParaNumber, formatarValor } from '@/utils/Functions/Formatacao/Formatacao'

  export default {
    name: 'ModalCadatroProduto',
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        type: Object,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarParaNumber,
        formatarValor,

        number: {},

        dados: {
          nome: null,
          valor: null,
        },
      }
    },
    mounted() {
      if (this.parametro) {
        this.dados = {
          id: this.parametro.id,
          nome: this.parametro.nome,
          valor: formatarValor(this.parametro.valor),
        }
      }
    },
    methods: {
      confirmar() {
        this.$refs.formularioCadastroProduto.validate().then((success) => {
          if (success) {
            if (this.dados.id) {
              this.editarProduto()
            } else {
              this.criarProduto()
            }
          }
        })
      },
      criarProduto() {
        const args = {
          nome: this.dados.nome,
          montanteInicial: formatarParaNumber(this.dados.valor),
          pessoa: capitalizeText(this.userData.name),
        }
        useJwt
          .post('produto', args)
          .then(() => {
            this.$message.success('Produto cadastrado com sucesso')
          })
          .catch((error) => {
            this.$message.error('Erro ao cadastrar produto')
          })
          .finally(() => {
            this.$emit('closeModal')
          })
      },
      editarProduto() {
        const args = {
          id: this.dados.id,
          nome: this.dados.nome,
          montanteInicial: formatarParaNumber(this.dados.valor),
        }
        useJwt
          .patch(`produto/${args.id}`, args)
          .then((response) => {
            this.$message.success('Produto alterado com sucesso')
          })
          .catch((error) => {
            this.$message.error('Erro ao alterar produto')
          })
          .finally(() => {
            this.$emit('closeModal')
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
