<template>
  <div>
    <b-card>
      <validation-observer ref="form">
        <b-form>
          <b-row md="12" class="mb-2">
            <b-col md="12">
              <h2>Validações</h2>
            </b-col>

            <b-col md="3">
              <b-form-group label="Termos e Condições do Banco" label-for="habilitaTermoECondicao">
                <validation-provider #default="{ errors }" name="Habilita Biometria Facial">
                  <b-form-checkbox
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaTermoECondicao"
                    name="habilitaTermoECondicao"
                    id="habilitaTermoECondicao"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaTermoECondicao ? 'Sim' : 'Não' }}
                  </b-form-checkbox>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaTermoECondicao"
                label="Título"
                class="mb-1"
                label-for="habilitaTermoECondicaoTitulo"
              >
                <validation-provider #default="{ errors }" name="Título do Termos e Condições do Banco" rules="required">
                  <b-input
                    id="habilitaTermoECondicaoTitulo"
                    placeholder="Título do Termos e Condições do Banco"
                    v-model="optionsLocal.habilitaTermoECondicaoTitulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaTermoECondicao"
                label="Texto do Termos e Condições do Banco"
                label-for="text"
                class="overflow"
              >
                <validation-provider #default="{ errors }" name="Texto do Termos e Condições do Banco" rules="required">
                  <vue-editor
                    id="text"
                    name="text"
                    rows="3"
                    placeholder="Texto do Termos e Condições do Banco"
                    v-model="optionsLocal.habilitaTermoECondicaoTexto"
                    class="tw-max-h-[50vh] tw-overflow-y-auto"
                    style="width: 100% !important"
                  ></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Termo e Condições Proposta" label-for="habilitaTermoECondicaoProposta">
                <validation-provider #default="{ errors }">
                  <b-form-checkbox
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaTermoECondicaoProposta"
                    name="habilitaTermoECondicaoProposta"
                    id="habilitaTermoECondicaoProposta"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaTermoECondicaoProposta ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaTermoECondicaoProposta"
                label="Título"
                class="mb-1"
                label-for="habilitaTermoECondicaoPropostaTitulo"
              >
                <validation-provider #default="{ errors }" name="Título do Termos e Condições Proposta" rules="required">
                  <b-input
                    id="habilitaTermoECondicaoPropostaTitulo"
                    placeholder="Título do Termos e Condições Proposta"
                    v-model="optionsLocal.habilitaTermoECondicaoPropostaTitulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaTermoECondicaoProposta"
                label="Texto do Termos e Condições Proposta"
                label-for="text"
                class="overflow"
              >
                <validation-provider #default="{ errors }" name="Texto do Termos e Condições Proposta" rules="required">
                  <vue-editor
                    id="text"
                    name="text"
                    rows="3"
                    placeholder="Texto do Termos e Condições Proposta"
                    v-model="optionsLocal.habilitaTermoECondicaoPropostaTexto"
                    class="tw-max-h-[50vh] tw-overflow-y-auto"
                    style="width: 100% !important"
                  ></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Aceite de Margem" label-for="habilitaAceiteDeMargem">
                <validation-provider #default="{ errors }" name="Aceite de Margem">
                  <b-form-checkbox
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaAceiteDeMargem"
                    name="habilitaAceiteDeMargem"
                    id="habilitaAceiteDeMargem"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaAceiteDeMargem ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaAceiteDeMargem"
                label="Título"
                class="mb-1"
                label-for="habilitaAceiteDeMargemTitulo"
              >
                <validation-provider #default="{ errors }" name="Título do Aceite de Margem" rules="required">
                  <b-input
                    id="habilitaAceiteDeMargemTitulo"
                    placeholder="Título do Aceite de Margem"
                    v-model="optionsLocal.habilitaAceiteDeMargemTitulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaAceiteDeMargem"
                label="Texto do Aceite de Margem"
                label-for="text"
                class="overflow"
              >
                <validation-provider #default="{ errors }" name="Texto do Aceite de Margem" rules="required">
                  <vue-editor
                    id="text"
                    name="text"
                    rows="3"
                    placeholder="Texto do Aceite de Margem"
                    v-model="optionsLocal.habilitaAceiteDeMargemTexto"
                    class="tw-max-h-[50vh] tw-overflow-y-auto"
                    style="width: 100% !important"
                  ></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Habilitar política de privacidade" label-for="habilitaPoliticaDePrivacidade">
                <validation-provider #default="{ errors }" name="Habilitar política de privacidade">
                  <b-form-checkbox
                    checked="false"
                    class="custom-control-primary"
                    v-model="optionsLocal.habilitaPoliticaDePrivacidade"
                    name="habilitaPoliticaDePrivacidade"
                    id="habilitaPoliticaDePrivacidade"
                    switch
                    inline
                  >
                    {{ optionsLocal.habilitaPoliticaDePrivacidade ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaPoliticaDePrivacidade"
                label="Título"
                class="mb-1"
                label-for="habilitaPoliticaDePrivacidadeTitulo"
              >
                <validation-provider #default="{ errors }" name="Título da política de privacidade" rules="required">
                  <b-input
                    id="habilitaPoliticaDePrivacidadeTitulo"
                    placeholder="Título da política de privacidade"
                    v-model="optionsLocal.habilitaPoliticaDePrivacidadeTitulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="optionsLocal.habilitaPoliticaDePrivacidade"
                label="Texto da política de privacidade"
                label-for="text"
                class="overflow"
              >
                <validation-provider #default="{ errors }" name="Texto da política de privacidade" rules="required">
                  <vue-editor
                    id="text"
                    name="text"
                    rows="3"
                    placeholder="Texto da política de privacidade"
                    v-model="optionsLocal.habilitaPoliticaDePrivacidadeTexto"
                    class="tw-max-h-[50vh] tw-overflow-y-auto"
                    style="width: 100% !important"
                  ></vue-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row md="12" style="margin-left: 0.1rem; margin-top: 5rem">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="width: 160px"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="authESalve"
            >
              {{ !isBusy ? 'Salvar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              style="width: 160px"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="limpar"
            >
              Limpar
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { VueEditor } from 'vue2-editor'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      AutenticaSenha,
      NaoAutorizadoModal,
      VueEditor,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        mode: null,
        props: JSON.parse(JSON.stringify(this.generalData)),
        isBusy: false,
        optionsLocal: {
          habilitaTermoECondicaoTitulo: '',
          habilitaTermoECondicaoTexto: '',

          habilitaTermoECondicaoPropostaTitulo: '',
          habilitaTermoECondicaoPropostaTexto: '',

          habilitaAceiteDeMargemTitulo: '',
          habilitaAceiteDeMargemTexto: '',

          habilitaPoliticaDePrivacidadeTitulo: '',
          habilitaPoliticaDePrivacidadeTexto: '',
        },
      }
    },
    mounted() {
      this.optionsLocal = {
        ...this.optionsLocal,

        habilitaTermoECondicao: this.props.habilitaTermoECondicao,
        habilitaTermoECondicaoProposta: this.props.habilitaTermoECondicaoProposta,
        habilitaAceiteDeMargem: this.props.habilitaAceiteDeMargem,
        habilitaPoliticaDePrivacidade: this.props.habilitaPoliticaDePrivacidade,

        habilitaTermoECondicaoTitulo: this.props.habilitaTermoECondicaoTitulo,
        habilitaTermoECondicaoTexto: this.props.habilitaTermoECondicaoTexto,

        habilitaTermoECondicaoPropostaTitulo: this.props.habilitaTermoECondicaoPropostaTitulo,
        habilitaTermoECondicaoPropostaTexto: this.props.habilitaTermoECondicaoPropostaTexto,

        habilitaAceiteDeMargemTitulo: this.props.habilitaAceiteDeMargemTitulo,
        habilitaAceiteDeMargemTexto: this.props.habilitaAceiteDeMargemTexto,

        habilitaPoliticaDePrivacidadeTitulo: this.props.habilitaPoliticaDePrivacidadeTitulo,
        habilitaPoliticaDePrivacidadeTexto: this.props.habilitaPoliticaDePrivacidadeTexto,
      }
    },
    methods: {
      limpar() {
        this.optionsLocal = {
          ...this.optionsLocal,
          habilitaTermoECondicao: null,
          habilitaTermoECondicaoProposta: null,
          habilitaAceiteDeMargem: null,
          habilitaPoliticaDePrivacidade: null,

          habilitaTermoECondicaoTitulo: null,
          habilitaTermoECondicaoTexto: null,

          habilitaTermoECondicaoPropostaTitulo: null,
          habilitaTermoECondicaoPropostaTexto: null,

          habilitaAceiteDeMargemTitulo: null,
          habilitaAceiteDeMargemTexto: null,

          habilitaPoliticaDePrivacidadeTitulo: null,
          habilitaPoliticaDePrivacidadeTexto: null,
        }
      },
      salvar() {
        this.isBusy = true

        const parametro = {
          habilitaTermoECondicao: this.optionsLocal.habilitaTermoECondicao,
          habilitaTermoECondicaoProposta: this.optionsLocal.habilitaTermoECondicaoProposta,
          habilitaAceiteDeMargem: this.optionsLocal.habilitaAceiteDeMargem,
          habilitaPoliticaDePrivacidade: this.optionsLocal.habilitaPoliticaDePrivacidade,

          habilitaTermoECondicaoTitulo: this.optionsLocal.habilitaTermoECondicaoTitulo,
          habilitaTermoECondicaoTexto: this.optionsLocal.habilitaTermoECondicaoTexto,

          habilitaTermoECondicaoPropostaTitulo: this.optionsLocal.habilitaTermoECondicaoPropostaTitulo,
          habilitaTermoECondicaoPropostaTexto: this.optionsLocal.habilitaTermoECondicaoPropostaTexto,

          habilitaAceiteDeMargemTitulo: this.optionsLocal.habilitaAceiteDeMargemTitulo,
          habilitaAceiteDeMargemTexto: this.optionsLocal.habilitaAceiteDeMargemTexto,

          habilitaPoliticaDePrivacidadeTitulo: this.optionsLocal.habilitaPoliticaDePrivacidadeTitulo,
          habilitaPoliticaDePrivacidadeTexto: this.optionsLocal.habilitaPoliticaDePrivacidadeTexto,
        }
        useJwt
          .put(`produto/termo/${this.props.id}`, parametro)
          .then(() => {
            this.$swal({
              title: 'Atenção!',
              text: 'Registro alterado com sucesso',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao salvar os parametros do termo')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      authESalve() {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          if (this.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
    },
  }
</script>
<style>
  .linhaDivisoria {
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }

  .dark-layout .linhaDivisoria {
    border-bottom: 1px solid #3b4253;
  }
</style>
