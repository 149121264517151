var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',{staticClass:"mb-2",attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"12"}},[_c('h2',[_vm._v("Validações")])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Termos e Condições do Banco","label-for":"habilitaTermoECondicao"}},[_c('validation-provider',{attrs:{"name":"Habilita Biometria Facial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"habilitaTermoECondicao","id":"habilitaTermoECondicao","switch":"","inline":""},model:{value:(_vm.optionsLocal.habilitaTermoECondicao),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicao", $$v)},expression:"optionsLocal.habilitaTermoECondicao"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.habilitaTermoECondicao ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.optionsLocal.habilitaTermoECondicao)?_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Título","label-for":"habilitaTermoECondicaoTitulo"}},[_c('validation-provider',{attrs:{"name":"Título do Termos e Condições do Banco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"habilitaTermoECondicaoTitulo","placeholder":"Título do Termos e Condições do Banco"},model:{value:(_vm.optionsLocal.habilitaTermoECondicaoTitulo),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicaoTitulo", $$v)},expression:"optionsLocal.habilitaTermoECondicaoTitulo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2075560275)})],1):_vm._e(),(_vm.optionsLocal.habilitaTermoECondicao)?_c('b-form-group',{staticClass:"overflow",attrs:{"label":"Texto do Termos e Condições do Banco","label-for":"text"}},[_c('validation-provider',{attrs:{"name":"Texto do Termos e Condições do Banco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"tw-max-h-[50vh] tw-overflow-y-auto",staticStyle:{"width":"100% !important"},attrs:{"id":"text","name":"text","rows":"3","placeholder":"Texto do Termos e Condições do Banco"},model:{value:(_vm.optionsLocal.habilitaTermoECondicaoTexto),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicaoTexto", $$v)},expression:"optionsLocal.habilitaTermoECondicaoTexto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3089475225)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Termo e Condições Proposta","label-for":"habilitaTermoECondicaoProposta"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"habilitaTermoECondicaoProposta","id":"habilitaTermoECondicaoProposta","switch":"","inline":""},model:{value:(_vm.optionsLocal.habilitaTermoECondicaoProposta),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicaoProposta", $$v)},expression:"optionsLocal.habilitaTermoECondicaoProposta"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.habilitaTermoECondicaoProposta ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.optionsLocal.habilitaTermoECondicaoProposta)?_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Título","label-for":"habilitaTermoECondicaoPropostaTitulo"}},[_c('validation-provider',{attrs:{"name":"Título do Termos e Condições Proposta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"habilitaTermoECondicaoPropostaTitulo","placeholder":"Título do Termos e Condições Proposta"},model:{value:(_vm.optionsLocal.habilitaTermoECondicaoPropostaTitulo),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicaoPropostaTitulo", $$v)},expression:"optionsLocal.habilitaTermoECondicaoPropostaTitulo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,669070221)})],1):_vm._e(),(_vm.optionsLocal.habilitaTermoECondicaoProposta)?_c('b-form-group',{staticClass:"overflow",attrs:{"label":"Texto do Termos e Condições Proposta","label-for":"text"}},[_c('validation-provider',{attrs:{"name":"Texto do Termos e Condições Proposta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"tw-max-h-[50vh] tw-overflow-y-auto",staticStyle:{"width":"100% !important"},attrs:{"id":"text","name":"text","rows":"3","placeholder":"Texto do Termos e Condições Proposta"},model:{value:(_vm.optionsLocal.habilitaTermoECondicaoPropostaTexto),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaTermoECondicaoPropostaTexto", $$v)},expression:"optionsLocal.habilitaTermoECondicaoPropostaTexto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2868984179)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Aceite de Margem","label-for":"habilitaAceiteDeMargem"}},[_c('validation-provider',{attrs:{"name":"Aceite de Margem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"habilitaAceiteDeMargem","id":"habilitaAceiteDeMargem","switch":"","inline":""},model:{value:(_vm.optionsLocal.habilitaAceiteDeMargem),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaAceiteDeMargem", $$v)},expression:"optionsLocal.habilitaAceiteDeMargem"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.habilitaAceiteDeMargem ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.optionsLocal.habilitaAceiteDeMargem)?_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Título","label-for":"habilitaAceiteDeMargemTitulo"}},[_c('validation-provider',{attrs:{"name":"Título do Aceite de Margem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"habilitaAceiteDeMargemTitulo","placeholder":"Título do Aceite de Margem"},model:{value:(_vm.optionsLocal.habilitaAceiteDeMargemTitulo),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaAceiteDeMargemTitulo", $$v)},expression:"optionsLocal.habilitaAceiteDeMargemTitulo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1839641162)})],1):_vm._e(),(_vm.optionsLocal.habilitaAceiteDeMargem)?_c('b-form-group',{staticClass:"overflow",attrs:{"label":"Texto do Aceite de Margem","label-for":"text"}},[_c('validation-provider',{attrs:{"name":"Texto do Aceite de Margem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"tw-max-h-[50vh] tw-overflow-y-auto",staticStyle:{"width":"100% !important"},attrs:{"id":"text","name":"text","rows":"3","placeholder":"Texto do Aceite de Margem"},model:{value:(_vm.optionsLocal.habilitaAceiteDeMargemTexto),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaAceiteDeMargemTexto", $$v)},expression:"optionsLocal.habilitaAceiteDeMargemTexto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,905254249)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Habilitar política de privacidade","label-for":"habilitaPoliticaDePrivacidade"}},[_c('validation-provider',{attrs:{"name":"Habilitar política de privacidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"false","name":"habilitaPoliticaDePrivacidade","id":"habilitaPoliticaDePrivacidade","switch":"","inline":""},model:{value:(_vm.optionsLocal.habilitaPoliticaDePrivacidade),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaPoliticaDePrivacidade", $$v)},expression:"optionsLocal.habilitaPoliticaDePrivacidade"}},[_vm._v(" "+_vm._s(_vm.optionsLocal.habilitaPoliticaDePrivacidade ? 'Sim' : 'Não')+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.optionsLocal.habilitaPoliticaDePrivacidade)?_c('b-form-group',{staticClass:"mb-1",attrs:{"label":"Título","label-for":"habilitaPoliticaDePrivacidadeTitulo"}},[_c('validation-provider',{attrs:{"name":"Título da política de privacidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"id":"habilitaPoliticaDePrivacidadeTitulo","placeholder":"Título da política de privacidade"},model:{value:(_vm.optionsLocal.habilitaPoliticaDePrivacidadeTitulo),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaPoliticaDePrivacidadeTitulo", $$v)},expression:"optionsLocal.habilitaPoliticaDePrivacidadeTitulo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,16503225)})],1):_vm._e(),(_vm.optionsLocal.habilitaPoliticaDePrivacidade)?_c('b-form-group',{staticClass:"overflow",attrs:{"label":"Texto da política de privacidade","label-for":"text"}},[_c('validation-provider',{attrs:{"name":"Texto da política de privacidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"tw-max-h-[50vh] tw-overflow-y-auto",staticStyle:{"width":"100% !important"},attrs:{"id":"text","name":"text","rows":"3","placeholder":"Texto da política de privacidade"},model:{value:(_vm.optionsLocal.habilitaPoliticaDePrivacidadeTexto),callback:function ($$v) {_vm.$set(_vm.optionsLocal, "habilitaPoliticaDePrivacidadeTexto", $$v)},expression:"optionsLocal.habilitaPoliticaDePrivacidadeTexto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2058204675)})],1):_vm._e()],1)],1),_c('b-row',{staticStyle:{"margin-left":"0.1rem","margin-top":"5rem"},attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",staticStyle:{"width":"160px"},attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.authESalve.apply(null, arguments)}}},[_vm._v(" "+_vm._s(!_vm.isBusy ? 'Salvar' : '')+" "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"width":"160px"},attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.limpar.apply(null, arguments)}}},[_vm._v(" Limpar ")])],1)],1)],1),_c('b-modal',{ref:"modal-auth-senha",attrs:{"id":"modal-auth-senha","centered":"","size":"sm","title":"Autenticação","hide-footer":""}},[_c('AutenticaSenha',{on:{"autenticarEvent":_vm.autenticarMethod}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }