<template>
  <div>
    <b-tabs pills>
      <b-tab title="Efetivo">
        <b-card-text>
          <CategoriaCriteriosElegibilidade :tipo="'Efetivo'" :userData="generalData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Estável">
        <b-card-text>
          <CategoriaCriteriosElegibilidade :tipo="'Estavel'" :userData="generalData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Pensionista">
        <b-card-text>
          <CategoriaCriteriosElegibilidade :tipo="'Pensionista'" :userData="generalData" />
        </b-card-text>
      </b-tab>
      <b-tab title="Aposentado">
        <b-card-text>
          <template>
            <CategoriaCriteriosElegibilidade :tipo="'Aposentado'" :userData="generalData" />
          </template>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import CategoriaCriteriosElegibilidade from './CategoriaCriteriosElegibilidade.vue'

  export default {
    components: {
      CategoriaCriteriosElegibilidade,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    mounted() {},
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
  }
</script>
