<template>
  <div>
    <b-row>
      <b-col class="w-100">
        <b-card>
          <div class="ml-3 mb-1 mt-2">
            <validation-observer ref="filtrosDePesquisa">
              <b-row class="form-row d-flex">
                <b-col cols="2">
                  <b-form-group label="Período" label-for="tipoDeFiltro">
                    <validation-provider #default="{ errors }" name="Período" rules="required">
                      <v-select-pt
                        id="componentePeriodoId"
                        name="tipoDeFiltro"
                        inputId="periodoId"
                        v-model="filtro.tipoDeFiltro"
                        :clearable="false"
                        :options="tipoDeFiltroOpcoes"
                        @input="carregarGrid"
                        :reduce="(option) => option.value"
                        label="text"
                        :disabled="isBusy"
                        :loading="isBusy"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </div>

          <b-col class="w-100 p-0" v-if="items.length">
            <div v-for="item in items" :key="item.id">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <b-card-title class="mb-0 ml-2 tituloDiaDoRecurso">
                      {{ String(formatarDataComDescricao(item.createdAt)) + ': ' }}
                    </b-card-title>
                  </b-col>
                </b-row>

                <b-card-body>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        borderless
                        responsive
                        class="position-relative"
                        :head-variant="null"
                        :per-page="item.perPage"
                        :items="item.recursos"
                        :fields="fields"
                        :current-page="item.currentPage"
                        sort-by="pessoa"
                      >
                        <template #foot()="">
                          <span>{{ '' }}</span>
                        </template>

                        <template #cell(tipoMovimentacao)="data">
                          <div v-if="data.value == 'entrada'">
                            <b-icon
                              icon="arrow-up-circle-fill"
                              variant="success"
                              scale="1.5"
                              style="margin-right: 0.5rem !important"
                            />
                            <span> Entrada </span>
                          </div>

                          <div v-else>
                            <b-icon
                              icon="arrow-down-circle-fill"
                              variant="danger"
                              scale="1.5"
                              style="margin-right: 0.5rem !important"
                            />
                            <span> Saída </span>
                          </div>
                        </template>

                        <template #cell(atividade)="data">
                          <span>{{ tipoAtividade[data.value] }}</span>
                        </template>
                      </b-table>

                      <b-card-body v-if="item.recursos.length > 1" class="d-flex justify-content-between flex-wrap pt-0">
                        <b-col md="auto">
                          <b-form-group
                            label="Por Página"
                            style="align-items: center"
                            label-cols="6"
                            label-align="left"
                            label-size="sm"
                            label-for="sortBySelect"
                            class="text-nowrap mb-md-0 mr-1"
                          >
                            <b-form-select id="perPageSelect" v-model="item.perPage" size="sm" inline :options="pageOptions" />
                          </b-form-group>
                        </b-col>

                        <b-col md="auto">
                          <b-pagination
                            v-model="item.currentPage"
                            :total-rows="item.recursos.length"
                            :per-page="item.perPage"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </b-col>

          <b-col v-else class="w-100 p-0">
            <b-card>
              <b-card-body class="d-flex flex-column justify-content-center align-items-center">
                <b-icon icon="exclamation-triangle" font-scale="2" class="text-center mb-1" />
                <h5 class="text-center">Nenhum registro encontrado!</h5>
              </b-card-body>
            </b-card>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import {
    capitalizeText,
    formatarValor,
    formatarValorPorcentagem,
    formatarParaNumber,
  } from '@/utils/Functions/Formatacao/Formatacao'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import Cleave from 'vue-cleave-component'
  import VSelectPT from '@/@core/components/v-select-pt/VSelectPT.vue'
  import * as dateFns from 'date-fns'
  import { ptBR } from 'date-fns/locale'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      Cleave,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarValor,
      capitalizeText,
      formatarValorPorcentagem,
      VSelectPT,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        filtro: { tipoDeFiltro: 'seteDias' },
        number: 0.0,

        formatarValor,
        formatarParaNumber,
        capitalizeText,
        formatarValorPorcentagem,

        tipoDeFiltroOpcoes: [
          { value: 'todos', text: 'Todos' },
          { value: 'seteDias', text: 'Últimos Sete Dias' },
          { value: 'trintaDias', text: 'Últimos Trinta Dias' },
          { value: 'noventaDias', text: 'Últimos Noventa Dias' },
          { value: 'semestre', text: 'Último Semestre' },
          { value: 'ano', text: 'Último Ano' },
        ],

        acesso: {
          ConfiguracoesSistemaRecursosCriar: null,
          ConfiguracoesSistemaRecursosEditar: null,
          ConfiguracoesSistemaRecursosExcluir: null,
        },

        //Campos tabela
        fields: [
          { key: 'tipoMovimentacao', label: 'Tipo', thClass: 'cabecalhoTabela' },
          { key: 'valor', label: 'Valor', formatter: 'formatarValor', thClass: 'cabecalhoTabela', sortable: true },
          { key: 'atividade', label: 'Atividade', thClass: 'cabecalhoTabela', sortable: true },
          { key: 'pessoa', label: 'Pessoa', formatter: 'capitalizeText', thClass: 'cabecalhoTabela', sortable: true },
        ],
        items: [],
        tipoAtividade: {
          consolidacaoContrato: 'Consolidação de Contrato',
          entradaDeRecursos: 'Entrada de Recursos',
        },

        //Padrão Tabela
        sortBy: 'competencia',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
        },

        // Totalizadores
        valorTotalEntrada: 0,
        valorTotalSaida: 0,
        quantidadeEntrada: 0,
        quantidadeSaida: 0,

        // Controle
        isFirstLoad: true,
      }
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.ConfiguracoesSistemaRecursosCriar = true
        this.acesso.ConfiguracoesSistemaRecursosEditar = true
        this.acesso.ConfiguracoesSistemaRecursosExcluir = true
      } else {
        this.acesso.ConfiguracoesSistemaRecursosCriar = this.userData.GrupoAcesso.ConfiguracoesSistemaRecursosCriar
        this.acesso.ConfiguracoesSistemaRecursosEditar = this.userData.GrupoAcesso.ConfiguracoesSistemaRecursosEditar
        this.acesso.ConfiguracoesSistemaRecursosExcluir = this.userData.GrupoAcesso.ConfiguracoesSistemaRecursosExcluir
      }

      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        const produtoId = this.generalData.id
        const tipoDeFiltro = this.filtro.tipoDeFiltro
        this.items = []
        this.valorTotalEntrada = 0
        this.valorTotalSaida = 0
        this.quantidadeEntrada = 0
        this.quantidadeSaida = 0

        useJwt
          .post('recurso/listarRecursos', { produtoId, tipoDeFiltro })
          .then((response) => {
            if (!response.data.length && !this.isFirstLoad) {
              return this.$message.error('Nenhum registro encontrado!')
            }
            this.items = response.data.map((item) => {
              return {
                ...item,
                perPage: 10,
                currentPage: 1,
              }
            })
            this.montaTotalizadores(response.data)
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
            this.isFirstLoad = false
          })
      },
      montaTotalizadores(data) {
        data.forEach((item) => {
          item.recursos.forEach((recurso) => {
            if (recurso.tipoMovimentacao === 'entrada') {
              this.valorTotalEntrada += formatarParaNumber(recurso.valor)
              this.quantidadeEntrada++
            } else {
              this.valorTotalSaida += formatarParaNumber(recurso.valor)
              this.quantidadeSaida++
            }
          })
        })
      },
      formatarDataComDescricao(data) {
        return dateFns.format(new Date(data), "dd 'de' MMMM 'de' yyyy", { locale: ptBR })
      },
    },
  }
</script>

<style>
  .tituloDiaDoRecurso {
    font-size: 1.8rem !important;
    font-weight: bold !important;
  }

  .cabecalhoTabela {
    background-color: white !important;
    border-bottom: 1px solid #97979b !important;
  }
</style>
